import { render, staticRenderFns } from "./SummaryTransactions.vue?vue&type=template&id=2de0faec&scoped=true&"
import script from "./SummaryTransactions.vue?vue&type=script&lang=js&"
export * from "./SummaryTransactions.vue?vue&type=script&lang=js&"
import style0 from "./SummaryTransactions.vue?vue&type=style&index=0&id=2de0faec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de0faec",
  null
  
)

export default component.exports