<template>
  <div class="transactions">
    <div>
      <h2 class="transactions__title heading-size-4">{{ $t('buy') }}</h2>
      <p v-if="!buyData.length">You have not made any purchase yet</p>
      <template v-else>
        <SummaryTransactionsItem
          v-for="(buy, index) in buyData"
          :value="buy"
          :key="index"
        />
      </template>
    </div>

    <div>
      <h2 class="transactions__title heading-size-4">{{ $t('sell') }}</h2>
      <p v-if="!sellData.length">{{ $t('availableSoon') }}</p>
      <template v-else>
        <SummaryTransactionsItem
          v-for="(sell, index) in sellData"
          :value="sell"
          :key="index"
        />
      </template>
    </div>
  </div>
</template>

<script>
import SummaryTransactionsItem from '@/components/Organisms/SummaryTransactionsItem';
export default {
  name: 'SummaryTransactions',
  components: {
    SummaryTransactionsItem,
  },
  props: {
    buyData: Array,
    sellData: Array,
  },
};
</script>

<style lang="scss" scoped>
.transactions {
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-gap: 48px;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  }
  > div {
    @media only screen and (max-width: 767px) {
      margin-bottom: 48px;
    }
  }

  &__title {
    margin-bottom: 12px;
  }
}
</style>
