<template>
  <div class="summary-page-wrap">
    <ProfileUserRegistration
      v-if="registrationModal || modalForm"
      @submitAddress="submitAddress"
      @submitPersonalInfo="submitPersonalInfo"
      :info="userInfo"
      @close="closeRegistration"
    />
    <div class="summary-page">
      <div class="summary-page__header">
        <div class="d-flex align-center">
          <NeoAvatar size="63" :photoURL="photoURL" :verified="$store.state.kycVerified"/>
          <div class="summary-page__title">
            <h1 class="ml-4 heading-size-3">{{ $t("myAccount") }}</h1>
            <h2 v-if="username" class="ml-4 heading-size-3">
              {{ username }}
            </h2>
          </div>
        </div>
      </div>

      <!--tabs-->
      <ul class="summary-navtabs">
        <template v-for="(tab, index) in tabs">
          <li
            :key="index"
            class="summary-navtab"
            :class="{ 'summary-navtab--active': currentPath === tab.key }"
          >
            <router-link
              :to="{ name: 'summary', params: tab.params }"
              @click.native="changeTab(tab.key)"
            >
              <button type="button">
                {{ tab.text }}
              </button>
            </router-link>
          </li></template
        >
      </ul>

      <!--contenttabs-->
      <div class="summary-contenttabs">
        <!--Profile-->
        <section
          class="summary-contenttab"
          :class="{ 'summary-contenttab--active': tabActive === 'profile' }"
        >
          <div class="summary-main">
            <div class="summary-invitations neo-card">
              <h2 class="summary-page__section-title heading-size-4 d-flex">
                <v-icon color="base" size="30" class="mr-2"
                  >mdi-credit-card-outline
                </v-icon>
                <div>{{ $t("invitations") }}</div>
              </h2>
              <SendInvitation />
            </div>
            <div class="summary-payments neo-card">
              <h2 class="summary-page__section-title heading-size-4 d-flex">
                <v-icon color="base" size="30" class="mr-2"
                  >mdi-credit-card-outline
                </v-icon>
                <div>{{ $t("myPM") }}</div>
              </h2>
              <UserPaymentMethods
                :user="currentUser"
                :defaultPM="userInfo.default_pm"
              />
            </div>

            <div class="summary-myinfo neo-card">
              <h2 class="summary-page__section-title heading-size-4 d-flex">
                <v-icon color="base" size="30" class="mr-2"
                  >mdi-face-man</v-icon
                >
                <div>{{ $t("myInfo") }}</div>
                <!-- <VerificationTag /> -->
              </h2>
              <ProfileDataList :info="userInfo" />

              <div class="mt-6 text-center">
                <v-btn @click="openForm" color="primary">{{
                  $t("editInfo")
                }}</v-btn>
              </div>
            </div>

            <div class="summary-blockchain neo-card">
              <h2 class="summary-page__section-title heading-size-4 d-flex">
                <v-icon color="base" size="30" class="mr-2"
                  >mdi-ethereum</v-icon
                >
                <div>Blockchain</div>
              </h2>
              <BlockchainInfo :info="web3" />
            </div>

            <div class="summary-shipping neo-card" v-if="false">
              <h2 class="summary-page__section-title heading-size-4 d-flex">
                <v-icon color="base" size="30" class="mr-2"
                  >mdi-office-building-marker-outline
                </v-icon>
                <div>{{ $t("myShippingAddress") }}</div>
              </h2>
              <UserAddress />
            </div>

            <!-- <div v-if="bookmarks.length > 0" class="summary-bookmarks neo-card">
              <h2 class="summary-page__section-title heading-size-4 d-flex">
                <v-icon color="base" size="30" class="mr-2"
                  >mdi-bookmark-multiple-outline
                </v-icon>
                <div>My Bookmarks</div>
              </h2>

              <SummaryBookmarks :value="bookmarks" />
            </div> -->
          </div>
        </section>

        <!--collection-->
        <section
          class="summary-contenttab"
          :class="{ 'summary-contenttab--active': tabActive === 'collection' }"
        >
          <div class="neo-card">
            <h2 class="summary-page__section-title heading-size-4 d-flex">
              <v-icon color="base" size="30" class="mr-2"
                >mdi-image-multiple-outline
              </v-icon>
              <div>{{ $t("myCollection") }}</div>
            </h2>
            <div class="summary-works">
              <SummaryWorkSimplified
                v-for="item in collection"
                :key="item.title"
                :item="item"
                :value="item"
              />
            </div>
          </div>
        </section>

        <!--transactions-->
        <section
          class="summary-contenttab"
          :class="{
            'summary-contenttab--active': tabActive === 'transactions',
          }"
        >
          <div class="neo-card">
            <h2 class="summary-page__section-title heading-size-4 d-flex">
              <v-icon color="base" size="30" class="mr-2">mdi-transfer </v-icon>
              <div>{{ $t("transactions") }}</div>
            </h2>
            <SummaryTransactions :buyData="buyData" :sellData="sellData" />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import UsersDS from "@/services/UsersDS";
import { db, rtdb, firebase } from "@/helpers/firebase";

// import VerificationTag from "@/components/Molecules/VerificationTag";
import ProfileDataList from "@/components/Organisms/ProfileDataList";
import SendInvitation from "@/components/Organisms/SendInvitation";
import BlockchainInfo from "@/components/Organisms/BlockchainInfo";
import SummaryTransactions from "@/components/Organisms/SummaryTransactions";
const SummaryWorkSimplified = () =>
  import("@/components/Organisms/SummaryWorkSimplified");
const UserAddress = () => import("@/components/Organisms/UserAddress");

const UserPaymentMethods = () =>
  import("@/components/Organisms/UserPaymentMethods");

const ProfileUserRegistration = () =>
  import("@/components/Organisms/ProfileUserRegistration");

import NeoAvatar from "@/components/Molecules/NeoAvatar";

export default {
  name: "SummaryView",
  props: {
    tabId: { type: String, default: "profile" },
  },
  components: {
    // VerificationTag,
    ProfileUserRegistration,
    ProfileDataList,
    BlockchainInfo,
    SendInvitation,
    // SummaryBookmarks,
    SummaryWorkSimplified,
    SummaryTransactions,
    UserAddress,
    UserPaymentMethods,
    NeoAvatar,
  },
  data: () => ({
    currentUser: "",
    web3: { address: "                    ", points: 0 },
    registrationModal: false,
    stripe: {},
    modalWait: false,
    modalReady: false,
    userInfo: {
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
      birthdate: "",
      identityURL: "",
      username: "",
      mailingList: true,
      telephone: { country: undefined, value: undefined, number: undefined },
      default_pm: "",
      photoURL: "",
      address: {
        line1: "",
        line2: "",
        city: "",
        region: "",
        postcode: "",
        country: "",
      },
    },

    tabActive: "profile",
    bookmarks: [
      // {
      //   artist: 'Goya',
      //   frames: 100,
      //   total: 400,
      //   percentage: 25,
      //   unitPrice: 250,
      //   unitPriceOrg: 100,
      //   currency: '$',
      //   certificate: 'http://test.me',
      //   trend: [100, 123, 90, 110, 150, 120],
      // },
    ],
    buyData: [],
    sellData: [],
    collection: [],
    stripeReady: false,
    modalForm: false,
    showOpenModalForAddCard: false,
    countries: ["Japan"],
    errorMessages: "",
    formHasErrors: false,
    photo: "",
    proofOfAddress: "",
    username: "",
    photoURL: "",
    paymentMethods: [],
    paymentMethod: {
      cus_id: "",
      pm_id: "",
      text: "",
    },
    unsubscribeUserVerification: undefined,
  }),
  computed: {
    tabs() {
      return [
        {
          text: this.$t("profile"),
          key: "profile",
          params: { tabId: "profile" },
        },
        {
          text: this.$t("collection"),
          key: "collection",
          params: { tabId: "collection" },
        },
        {
          text: this.$t("transactions"),
          key: "transactions",
          params: { tabId: "transactions" },
        },
      ];
    },
    // form() {
    //   return {
    //     name: this.name,
    //     address: this.address,
    //     city: this.city,
    //     state: this.state,
    //     zip: this.zip,
    //     country: this.country,
    //   };
    // },
    currentPath() {
      return this.$route.path.split("/").pop();
    },
    missingVerifications() {
      return (
        !this.$store.state.isVerified ||
        !this.$store.state.user.phoneNumber ||
        !this.$store.state.user.emailVerified
      );
    },
  },
  watch: {
    name() {
      this.errorMessages = "";
    },
    info: function (data) {
      // watch it
      this.populateUserData(data);
    },
    "$store.state.kycVerified": function () {
      if (this.$store.state.kycVerified)
        this.registrationModal = this.missingVerifications;
      this.refreshUser();
    },
    "$store.state.user": function () {
      this.refreshUser();
    },
  },
  methods: {
    closeRegistration() {
      this.registrationModal = false;
      this.modalForm = false;
    },
    getUserInfo() {
      this.openForm();
    },
    addCardButtonClicked() {
      this.addCardModalIsOpen = true;
    },
    changeTab(tabClicked) {
      this.tabActive = tabClicked;
    },
    openForm() {
      this.modalForm = true;
    },
    closeForm() {
      this.modalForm = false;
    },
    toggleModalForAddCard() {
      this.showOpenModalForAddCard = !this.showOpenModalForAddCard;
    },
    async deletePaymentMethod(pm_id) {
      const cardDoc = await db
        .collection("stripe_customers")
        .doc(this.$store.state.user.uid)
        .collection("payment_methods")
        .where("id", "==", pm_id)
        .get();
      await cardDoc.docs[0].ref.delete();
      this.paymentMethods.find((pm, index) => {
        if (pm.pm_id === pm_id) {
          this.paymentMethods.splice(index, 1);
        }
      });
    },
    async submitPersonalInfo(modalInfo) {
      if (modalInfo.photos.avatar && modalInfo.photos.avatar.isNew) {
        UsersDS.updateAvatar(
          modalInfo.photos.avatar.fileName,
          modalInfo.photos.avatar.data
        );
        setTimeout(() => {
          this.photoURL = `${process.env.VUE_APP_IMAGES_URL}avatars/${
            this.$store.state.user.uid
          }.jpg?${Date.now()}`;
        }, 2000);
      }

      const info = {
        birthdate: modalInfo.userInfo.birthdate,
        mailingList: modalInfo.userInfo.mailingList,
        firstName: modalInfo.userInfo.firstName,
        lastName: modalInfo.userInfo.lastName,
        firstNameKana: modalInfo.userInfo.firstNameKana,
        lastNameKana: modalInfo.userInfo.lastNameKana,
        telephone: modalInfo.userInfo.telephone,
        username: modalInfo.userInfo.username,
        photoURL: `${process.env.VUE_APP_IMAGES_URL}avatars/${this.$store.state.user.uid}.jpg`,
      };
      this.userInfo = { ...this.userInfo, ...info };
      this.username = this.userInfo.username;
      this.photoURL = "";
      this.photoURL = `${process.env.VUE_APP_IMAGES_URL}avatars/${
        this.$store.state.user.uid
      }.jpg?${Date.now()}`;
      this.$store.state.user.updateProfile({
        displayName: modalInfo.userInfo.username,
        photoURL: this.photoURL,
      });
      setTimeout(async () => {
        let currentUser = await firebase.auth().currentUser;
        if (currentUser) {
          await currentUser.reload();
          currentUser = await firebase.auth().currentUser;
          this.$store.commit("updateUser", currentUser);
        }
      }, 1000);

      // Update NOA user info
      UsersDS.update(this.$store.state.user.uid, this.userInfo);
    },
    submitAddress(modalInfo) {
      const info = {
        address: modalInfo.address,
      };
      this.userInfo = { ...this.userInfo, ...info };
      // Update NOA user info
      UsersDS.update(this.$store.state.user.uid, this.userInfo);
    },
    populateUserData(info) {
      Object.keys(info).forEach((key) => {
        this.$data[key] = info[key];
      });
    },
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function (e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe() {
      /* global Stripe */
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBKEY);
      this.stripeReady = true;
    },
    async refreshUser() {
      if (!this.$store.state.user) return;
      this.photoURL = `${process.env.VUE_APP_IMAGES_URL}avatars/${this.$store.state.user.uid}.jpg`;
      const userInfoSnapshot = await UsersDS.read(this.$store.state.user.uid);
      if (userInfoSnapshot.exists) {
        const userInfo = userInfoSnapshot.data();
        this.web3.address = userInfo.ethAddress || "                    ";
        this.web3.points = 0;
        this.userInfo = { ...this.userInfo, ...userInfo };
        this.$store.commit("setUserData", this.userInfo);
        this.username = this.userInfo.username;
      } else {
        this.username = this.$store.state.user.displayName
          ? this.$store.state.user.displayName
          : "";
        this.userInfo.username = this.username;
      }
      if (this.$store.state.isVerified) {
        // Transactions
        const userTransactions = UsersDS.getTransactions(
          this.$store.state.user.uid
        );
        // let aggregatedCollection = {};
        userTransactions.then((data) => {
          this.buyData = [];
          this.sellData = [];
          const docs = data.docs;
          docs.forEach((doc) => {
            // Generate transaction data
            const transaction = doc.data();
            transaction.id = doc.id;
            transaction.date = new Date(transaction.timestamp);
            if (transaction.type === "buy") {
              this.buyData.push(transaction);
            } else {
              this.sellData.push(transaction);
            }
          });
        });

        const snapshot = await UsersDS.getCollectorsArtwork(
          this.$store.state.user.uid
        );

        this.collection = [];
        if (snapshot.exists()) {
          const collection = snapshot.val();
          for (let artworkId in collection) {
            const summary = collection[artworkId];
            summary.id = artworkId;
            summary.totalWorkValue = summary.totalFrames * summary.paidValue;
            summary.paidValue = summary.quantity * summary.paidValue;
            summary.trend = [0, 1000, 1000, 1000, 1000]; // TODO once secondary market starts
            summary.img = `${process.env.VUE_APP_IMAGES_URL}artwork/${artworkId}/${artworkId}_1.jpg`;
            this.collection.push(summary);
          }
        }
      }
    },
  },
  async mounted() {
    if (!this.$store.state.user.emailVerified)
      setTimeout(async () => {
        let currentUser = await firebase.auth().currentUser;
        if (currentUser) {
          await currentUser.reload();
          currentUser = await firebase.auth().currentUser;
          this.$store.commit("updateUser", currentUser);
        }
      }, 1000);
  },
  async beforeMount() {
    this.$store.commit("refreshWebsiteVersion");

    let currentUser = await firebase.auth().currentUser;
    if (currentUser) {
      await currentUser.reload();
      currentUser = await firebase.auth().currentUser;
      this.$store.commit("updateUser", currentUser);
    }
    const statusSnapshot = await rtdb
      .ref("/users/" + this.$store.state.user.uid + "/status")
      .once("value");
    if (statusSnapshot.exists()) {
      const status = statusSnapshot.val();
      this.$store.commit("setVerified", status);
    } else {
      this.$store.commit("setVerified", { verified: false, pending: false });
    }
    // User profile info
    this.tabActive = this.tabId;
    this.refreshUser();
    this.registrationModal = this.missingVerifications;
  },
};
</script>

<style lang="scss" scoped>
.summary-page-wrap {
  background-color: var(--color-white);
  height: 100%;
}

.summary-page {
  height: 100%;
  padding: 24px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__header {
    text-align: left;
    width: var(--main-width-container);
    margin: auto;
  }

  &__title {
    h1, h2 {
      line-height: 1;
    }
  }

  &__section-title {
    font-weight: 400;
    margin-bottom: 24px;
  }

  &__avatar {
    border-radius: 50%;
    width: 65px;
    height: 65px;
    border: 2px solid var(--color-primary);
    display: flex;
    overflow: hidden;
    img {
      object-position: center;
      object-fit: cover;
    }
  }
}

.summary-navtabs {
  margin: 24px auto auto;
  padding: 0;
  display: flex;
  width: var(--main-width-container);
}

.summary-navtab {
  margin-right: 4px;
  font-weight: 600;
  padding: 8px 12px;
  &--active {
    color: var(--color-black);
    display: flex;
    color: var(--color-primary);
    button {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 5px;
        background: var(--color-primary);
        border-radius: 6px 6px 0 0;
      }
    }
  }
  &:hover {
    color: var(--color-primary);
  }
}

.summary-contenttabs {
  flex-grow: 1;
  padding: 24px 12px;
  border-top: 1px solid var(--color-grey);
  background: var(--color-grey-light);
}

.summary-contenttab {
  display: none;
  &--active {
    width: var(--main-width-container);
    margin: auto;
    display: block;
  }
}

.summary-main {
  display: grid;
  align-items: start;
  grid-gap: 24px;
  grid-template-areas:
    "invitations"
    "payments"
    "myinfo"
    // "shipping"
    "blockchain";
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
    "invitations invitations myinfo myinfo"
      "payments payments blockchain blockchain";
      // "invitations invitations myinfo myinfo payments payments"
      // "shipping shipping blockchain blockchain";
  }
  @media only screen and (min-width: 1000px) {
    grid-template-columns: repeat(
      3,
      1fr
    ); // Set to 3 when addresses are enabled
    grid-template-areas: "invitations myinfo payments";
  }
}
.summary-invitations {
  grid-area: invitations;
}
.summary-myinfo {
  grid-area: myinfo;
}
.summary-blochain {
  grid-area: blockchain;
}
.summary-payments {
  grid-area: payments;
}
.summary-shipping {
  grid-area: shipping;
}
.summary-works {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
