<template>
  <ul class="profile-datalist">
    <li v-if="info.address">
      <span>Wallet</span>
      <span class="font-weight-bold">{{ info.address }}</span>
    </li>
    <li v-if="info.points !== undefined">
      <span>NOA Points</span>
      <span class="font-weight-bold">{{ info.points }}</span>
    </li>
    <p class="font-weight-bold text-center">{{ $t('availableSoon') }}...</p>
  </ul>
</template>
<script lang="ts">
export default {
  name: 'ProfileDataList',
  props: {
    info: Object,
  },
};
</script>
<style lang="scss" scoped>
.profile-datalist {
  margin-top: 16px;
  @media only screen and (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    margin-bottom: 8px;
    @media only screen and (min-width: 1200px) {
      flex-basis: 50%;
    }
    > :first-child {
      margin-right: 4px;
    }
    &.long {
      flex-basis: 100%;
    }
    &.separator {
      padding-top: 8px;
      border-top: 1px solid var(--color-grey);
    }
  }
}
</style>
