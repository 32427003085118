<template>
  <ul class="profile-datalist">
    <li  v-if="info.lastName">
      <span>{{ $t("lastName") }}</span>
      <span class="font-weight-bold">{{ info.lastName }}</span>
    </li>
    <li v-if="info.firstName">
      <span>{{ $t("firstName") }}</span>
      <span class="font-weight-bold">{{ info.firstName }}</span>
    </li>
    <li v-if="info.lastNameKana">
      <span>{{ $t("surnameKana") }}</span>
      <span class="font-weight-bold">{{ info.lastNameKana }}</span>
    </li>
    <li v-if="info.firstNameKana">
      <span>{{ $t("nameKana") }}</span>
      <span class="font-weight-bold">{{ info.firstNameKana }}</span>
    </li>
    <li v-if="info.birthdate">
      <span>{{ $t("birthdate") }}</span>
      <span class="font-weight-bold">{{ info.birthdate }}</span>
    </li>
    <li v-if="info.telephone">
      <span>{{ $t("phoneNumber") }}</span>
      <span class="font-weight-bold">{{ info.telephone.number }}</span>
    </li>
    <li class="long" v-if="info.email">
      <span>{{ $t("email") }}</span>
      <span class="font-weight-bold">{{ info.email }}</span>
    </li>
    <li class="long separator" v-if="info.address.line1">
      <span>{{ $t("addressLine1") }}</span>
      <span class="font-weight-bold">{{ info.address.line1 }}</span>
    </li>
    <li class="long" v-if="info.address.line2">
      <span>{{ $t("addressLine2") }}</span>
      <span class="font-weight-bold">{{ info.address.line2 }}</span>
    </li>

    <li v-if="info.address.city">
      <span>{{ $t("city") }}</span>
      <span class="font-weight-bold">{{ info.address.city }}</span>
    </li>
    <li v-if="info.address.postcode">
      <span>{{ $t("postcode") }}</span>
      <span class="font-weight-bold">{{ info.address.postcode }}</span>
    </li>
    <li v-if="info.address.region">
      <span>{{ $t("region") }}</span>
      <span class="font-weight-bold">{{ info.address.region }}</span>
    </li>
    <li v-if="info.address.country">
      <span>{{ $t("country") }}</span>
      <span class="font-weight-bold">{{ info.address.country }}</span>
    </li>
  </ul>
</template>
<script lang="ts">
export default {
  name: "ProfileDataList",
  props: {
    info: {
      type: Object,
      default: function () {
        return {
          firstName: "",
          lastName: "",
          firstNameKana: "",
          lastNameKana: "",
          birthdate: "",
          identityURL: "",
          username: "",
          mailingList: true,
          telephone: { country: undefined, value: undefined },
          default_pm: "",
          address: {
            line1: "",
            line2: "",
            city: "",
            region: "",
            postcode: "",
            country: "",
          },
        };
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.profile-datalist {
  margin-top: 16px;
  @media only screen and (min-width: 1000px) {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    margin-bottom: 8px;
    @media only screen and (min-width: 1000px) {
      flex-basis: 50%;
    }
    > :first-child {
      margin-right: 4px;
    }
    &.long {
      flex-basis: 100%;
    }
    &.separator {
      padding-top: 8px;
      border-top: 1px solid var(--color-grey);
    }
  }
}
</style>
