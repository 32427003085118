<template>
  <div class="contact-wrapper">
    <slot></slot>
    <div class="neo-card mt-6">
      <h3>{{ `${invitationsLeft} ${$t("invitationsLeft")}` }}</h3>

      <template v-if="invitationsLeft > 0">
        <p>
          {{ $t("invitationMessage") }}
        </p>
        <v-form ref="contactForm" v-model="valid" lazy-validation>
          <div class="neo-text-field">
            <div>{{ $t("email") }}</div>
            <v-text-field
              v-model="email"
              flat
              solo
              outlined
              dense
              :label="$t('email')"
              required
              :success-messages="invitationSentMessage"
              :error-messages="invitationSentErrorMessage"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </div>
          <div class="text-center mt-4">
            <v-btn
              large
              :disabled="!valid || disabled || waiting"
              color="primary"
              id="send-message"
              @click="validate"
            >
              <v-icon class="mr-2">mdi-send</v-icon>
              {{ $t("send") }}
              <v-icon size="32" v-if="waiting" class="ml-1 waiting">
                mdi-tire
              </v-icon>
            </v-btn>
            <VueRecaptcha
              :sitekey="recaptchaKey"
              @verify="onVerify"
              @expired="onExpired"
              ref="recaptcha"
              size="invisible"
              badge="inline"
              type="contact"
              :loadRecaptchaScript="false"
              theme="light"
            ></VueRecaptcha>
          </div>
        </v-form>
        <p class="reCAPTCHA-disclaimer text-center">
          This site is protected by reCAPTCHA and the Google
          <a target="_blank" href="https://policies.google.com/privacy"
            >Privacy Policy</a
          >
          and
          <a target="_blank" href="https://policies.google.com/terms"
            >Terms of Service</a
          >
          apply.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import UsersDS from "@/services/UsersDS";
import { firebase } from "@/helpers/firebase";

export default {
  name: "SendInvitation",
  components: {
    VueRecaptcha,
  },
  data: () => ({
    waiting: false,
    invitationsLeft: 0,
    invitationSentErrorMessage: "",
    invitationSentMessage: "",
    recaptchaKey: process.env.VUE_APP_RECAPTCHA_SITE,
    recaptchaOk: false,
    valid: false,
    email: "",
    disabled: false,
    fieldRules: [(v) => !!v || "Required"],
    emailRules: [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    rules: {
      required: (value) => !!value || "Required.",
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    },
  }),
  methods: {
    errorMethod(e) {
      console.log("errorMethod ", e);
    },
    expiredMethod(e) {
      console.log("expiredMethod ", e);
    },
    validate() {
      if (this.$refs.contactForm.validate()) this.sendMessage();
    },
    onVerify(response) {
      if (response !== "") {
        this.sendMail();
      } else {
        return false;
      }
    },
    onExpired() {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
      this.disabled = true;
    },
    sendMessage() {
      console.log("send message");
      this.$refs.recaptcha.execute();
    },
    sendMail() {
      this.waiting = true;
      firebase
        .app()
        .functions("asia-northeast1")
        .httpsCallable("sendInvitation")({ email: this.email, lang: this.$store.state.lang })
        .then((res) => {
          console.log(res);
          this.waiting = false;
          this.sent = true;
          this.resetForm();
          this.refreshInvitations();
          if (res.data.status === 0) {
            this.invitationSentErrorMessage === "";
            this.invitationSentMessage === this.$t("invitationSent");
          } else {
            this.invitationSentMessage === "";
            this.invitationSentErrorMessage === this.$t("invitationError");
          }
        })
        .catch(() => {
          // Getting the Error details.
          this.waiting = false;
          this.invitationSentMessage === "";
          this.invitationSentErrorMessage === this.$t("invitationError");
          this.refreshInvitations();
        });
    },
    resetForm() {
      this.contactFormData = {
        email: "",
      };
    },
    refreshInvitations() {
      UsersDS.getUserInvitations().then((snap) => {
        if (snap.exists()) {
          const invitationInfo = snap.val();
          this.invitationsLeft = invitationInfo.available || 0;
        }
      });
    },
  },
  mounted() {
    this.refreshInvitations();
  },
};
</script>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
  position: absolute;
}
</style>

<style lang="scss" scoped>
.reCAPTCHA-disclaimer {
  margin-top: 15px;
  font-size: var(--font-size-super-small);
}
// .contact-wrapper {
//   margin: auto;
//   @media only screen and (min-width: 1024px) {
//     max-width: 80%;
//   }
// }
</style>
